<template lang="pug">
  button.outline-none.border-none.w-full.shadow-bx-small-sm.transition.duration-500.flex.items-center.justify-center.flex-wrap(
    @click="handleClick"
    :type="type"
    :class="electedTheme + '' + electedSize + (disabled ? ' changeOpacity cursor-not-allowed' : '') + ' ' + aditional"
    :disabled="disabled"
  )
    span(
      v-if="icon"
      :class="sizeXLDK ? 'sizeXLDK' : ''"
    ).material-icons-outlined.mr-2 {{ icon }}
    | {{ text }}
</template>

<script>
export default {
  name: "Button",
  props: {
    text: String,
    themeSelected: String,
    size: String,
    type: String,
    icon: String,
    onClick: Function,
    disabled: Boolean,
    sizeXLDK: Boolean,
    aditional: {
      default: "",
      type: String,
    },
  },
  data: () => {
    return {
      themes: {
        primary: {
          text: "text-gray",
          bg: "bg-primary",
          shadow: "primaryShadow",
        },
        primaryOther: {
          text: "text-gray",
          bg: "bg-primary_other",
          shadow: "primaryShadow",
        },
        secondary: {
          text: "text-gray",
          bg: "bg-secondary",
          shadow: "secondaryShadow",
        },
        light: {
          text: "text-primary",
          bg: "bg-white",
          shadow: "lightShadow",
        },
        danger: {
          text: "text-danger_background",
          bg: "bg-danger_color",
          shadow: "lightShadowDanger",
        },
      },
    };
  },
  computed: {
    electedTheme() {
      return `
        ${this.themes[this.themeSelected] ? this.themes[this.themeSelected].text : this.themes.primary.text}
        ${this.themes[this.themeSelected] ? this.themes[this.themeSelected].bg : this.themes.primary.bg}
        ${this.themes[this.themeSelected] ? this.themes[this.themeSelected].shadow : this.themes.primary.shadow}
      `;
    },
    electedSize() {
      return this.size || "p-3 py-4 rounded-bd-large-1";
    },
  },
  methods: {
    handleClick() {
      if (this.onClick) {
        this.onClick();
      }
    },
  },
}
</script>

<style scoped>
  .lightShadow {
    box-shadow: 1px 4px 13px #204a971a;
  }
  .lightShadowDanger {
    box-shadow: 1px 4px 13px #e74a7962;
  }
  .secondaryShadow {
    box-shadow: 0px 5px 8px #236f9570;
  }
  .primaryShadow {
    box-shadow: 0px 5px 8px #1f469594;
  }
  .changeOpacity {
    background: #6ab3d8fb;
    color: rgb(235, 232, 232);
  }
  .sizeXLDK {
    font-size: 1.5rem;
  }
</style>